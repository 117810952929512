.placar-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.placar-container button {
  border: none;
  position: absolute;
  background-color: transparent;
  color: #e45ca8;
  top: -15px;
  z-index: 2382173;
}