body {
  background: linear-gradient(-90deg, #2534487d, #8180a77d);
  /* background: black url(https://bridou.com/background.jpg); */
  background: black url(/background.webp);
  height: 100vh;
  height: 100svh;
  box-sizing: border-box;
}

#root,
.App {
  height: 100%;
}

.App {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.border-transparent {
  border-color: transparent !important;
}

button {
  outline: 0;
}

button:focus {
  outline: 0 !important;
}

/* SPINNER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 78px;
  height: 112px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff63;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* FIM DO SPINNER */

.my-100 {
  font-family: 'Roboto', Georgia, Times, serif !important;
  /*text-shadow: #d2272c87 2px -2px 5px;*/
  font-weight: 500;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: #d2272c;
}

.navbar-collapse {
  margin-top: -13px !important;
}

.logo {
  animation: fadeIn ease-in-out 2s;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-top: -6px;
}

.loading-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: #000;
}

.loaded-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  background-color: transparent;
}

.my-100:hover {
  text-decoration: none !important;
  color: #deb24a !important;
}

.get-players-names {
  margin-top: 1.4rem;
}

.get-players-names h2 {
  color: #d3a73f;
}

.form-control {
  font-weight: bold !important;
}

/*Navbar Icons*/

.fa-home,
.fa-bullseye {
  font-size: 0.8em;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #eacf91e0 !important;
  color: #f2e8e0f0 !important;
}

.get-players-names h5 {
  color: white;
}

.start-button {
  background-color: rgba(210, 39, 44, 0.81) !important;
  border-color: #18161557 !important;
  box-shadow: 0px 0px 20px 0px black !important;
  font-weight: bold;
}

.btn-no-color {
  border-color: #18161557 !important;
  box-shadow: 0px 0px 20px 0px black !important;
  font-weight: bold;
}

.bluez {
  background-color: #2f3e66 !important;
  border-color: #18161557 !important;
  box-shadow: 0px 0px 20px 0px black !important;
  font-weight: bold;
}

/*Passphrase input initial screen margin*/

.state-pass-input {
  display: flex;
  position: relative;
  box-shadow: #000000e6 0px 1px 7px 1px;
  margin: 8px 0;
  caret-color: #776436;
}

#fetch-game {
  position: absolute;
  right: 0px;
  align-self: center;
  margin: 0 10px;
  color: #e0e0e0;
  font-size: 22px;
}

#initial-screen {
  display: flex;
  flex-direction: column;
}

#recover-game {
  margin-top: auto;
  margin-bottom: 50px;
  color: #fff;
}

#recover-game a {
  text-decoration: none;
  color: white;
}

.initial-screen-input {
  background-color: #0000004a !important;
  border: 2px solid #a59f9266;
  font-size: 13px;
}

.navbar div a:hover:focus,
.navbar div a:hover:hover {
  text-decoration: none;
  color: #deb85f !important;
}

.placar-table {
  padding-top: 20px;
  overflow: auto;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  height: calc(100% - 40px);
}

.placar-table table {
  width: -webkit-fill-available;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #211d1db3;
  color: #e2e2e2;
  box-shadow: 0px 0px 20px 0px #0a121c;
}

.box-shadow-cool {
  box-shadow: 1px 0px 6px 2px #de884a96;
}

.pedidos-btn {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: auto;
  font-size: 30px;
  padding: 5px;
  border: 3px solid #adadadcf;
  box-shadow: #212121 5px 2px 8px;
  background-color: #fff3;
  color: #f9c649;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  outline: 0;
}

.fadein {
  animation: fadeIn ease-in-out 1s;
}

/* placar */

table.placar-portrait {
  animation: fadeIn ease-in-out 2s;
}

.btn-primary:focus {
  box-shadow: 3px 2px 14px 0.1rem #dca22b59;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

table.placar-landscape {
  animation: fadeIn ease-in-out 2s;
  border: 2px solid #656b8d;
}

.placar-portrait-row {
  font-weight: bold;
  font-size: 2rem;
}

.td-portrait-name {
  text-align: left;
  padding-left: 15px;
}

.td-portrait-pontos {
  text-align: right;
  padding-right: 12px;
}

.time-started {
  background: linear-gradient(-90deg, #00000026, #c14649);
  width: 33%;
  margin-left: 15px;
  color: #d8d8d8;
  padding-left: 8px;
  display: inline-block;
  animation: fadeIn ease-in-out 5s;
}

.time-ended {
  background: linear-gradient(-90deg, #c14649, #00000026);
  width: 33%;

  color: #d8d8d8;

  display: inline;
  float: right;
  margin-right: 15px;
  text-align: right;
  padding-right: 8px;
  animation: fadeIn ease-in-out 5s;
}

.b-star {
  font-size: 0.9em;
  margin-left: 7px;
  animation-name: blinkingStar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

#status-list {
  height: 32vh;
  width: 100%;
  position: relative;
}

.navigation-button {
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 98px;
  justify-content: space-evenly;
}

.navigation-button:hover {
  text-decoration: none;
}

.list-container {
  display: flex;
  flex-direction: column;
  color: #bfbfbf;
  height: 100%;
  width: 100%;
  position: relative;
}

.list-body {
  background-color: #ab9d9d1a;
  overflow: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  animation: 1.5s listSlide;
}

.list-body::-webkit-scrollbar {
  background-color: #000000a3;
}
.list-body::-webkit-scrollbar-button {
}

.list-body::-webkit-scrollbar-thumb {
  background-color: #7e91a2;
}
.list-body::-webkit-scrollbar-track {
}

.order-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  height: 135px;
  font-size: 13px;
  background: #000000bd;
  right: 30px;
  top: 2px;
  padding: 10px 15px;
  font-weight: 400;
  border-radius: 5px;
  animation: fadeIn 800ms;
  box-shadow: 1px 1px 5px 0px #000000b3;
}

.order-menu-item[font-weight='600'] {
  font-weight: 700;
  color: #fff;
}

.desc-status {
  position: absolute;
  background: #000000de;
  animation: slideUp 800ms;
  bottom: 20px;
  opacity: 1;
  box-shadow: -0.5px -2px 5px 0px #d4a43e17;
  width: 100%;
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
}

.desc-status.selected {
  animation: fadeIn 800ms;
  bottom: 0;
}

.overflow-status {
  background-color: #8685856b;
  position: absolute;
  width: 100%;
}

.single-winner.selected {
  border-bottom: 1px solid #00000033;
  background-color: #4a4a4a73;
  font-weight: 600 !important;
}

.btn-new-game {
  border: 1px solid #6363634d;
  height: 46px;
  font-weight: 600;
  cursor: pointer;
  background: #0000009e;
  color: #c5c5c5;
  width: 300px;
  margin: auto;
  box-shadow: 1px 1px 8px 3px #0c1721eb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    bottom: 5px;
    opacity: 0.3;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
}
@keyframes slideDown {
  from {
    top: 0;
    opacity: 0.3;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}

@keyframes listSlide {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes navHeight {
  from {
    height: 100px;
  }

  to {
    height: 72px;
  }
}

@keyframes blinkingStar {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 800px) {
  body {
    background: black url(/background.webp);
    background-size: contain;
    background-repeat: no-repeat;
    backdrop-filter: brightness(0.5);
    background-position-y: bottom;
  }
}
