.cont-div ul {
  list-style: none;
  font-size: 2rem;
}

ul {
  list-style: none;
  font-size: 2rem;
}

li {
  list-style: none;
  font-size: 2rem;
  background-color: #151f2c40;
}

.list-of-players {
  padding-left: 0;
}

.cont-div {
  padding: 10vh 20vw;
}

.btniz {
  right: 20vw;
  position: fixed;
  color: red;
  background-color: transparent;
  border: none;
  text-shadow: 1px 1px black;
}