.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: auto;
  font-size: 30px;
  padding: 5px;
  border: 3px solid #333231 !important;
  box-shadow: #2121217a 5px 2px 8px;
  background-color: #000000c2;
  color: #d0272d;
}